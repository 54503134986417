import { axiosInstance } from './interceptors';
import { IOfferRate } from "../interfaces";

export const customerList = async () => {
    return await axiosInstance.get(`/customer/list`);
  };
export const getAll = async (limit: Number,page: Number) => {
    return await axiosInstance.get(`/master/rate/list?page=${page}&limit=${limit}`);
  };

export const get = async (id: string) => {
    return await axiosInstance.get(`master/rate/getById/${id}`);
};
export const save = async (req: any) => {
    return await axiosInstance.post(`master/rate/`, req);
};

export const serchCustomer = async (name:any) => {
  return await axiosInstance.get(`/master/offer/search?name=${name}`);
};

export const update = async ( id: string|null, req: IOfferRate) => {
    return await axiosInstance.put(`master/rate/update/${id}`,  req );
};

export const Delete = async (id: string) => {
  return await axiosInstance.delete(`master/rate/delete/${id}`);
};