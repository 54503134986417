import * as Yup from 'yup';

export const customerSave = Yup.object().shape({
    first_name: Yup.string().required('Required').max(50, "max limit 50").min(3, "min limit 3"),
    last_name: Yup.string().max(50, "max limit 50").min(1, "Min limit 1").required('Required'),
    email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),

  phone: Yup.string()
    .matches(/^\d{3} \d{3}-\d{4}$/, 'Phone number must match format 999 999-9999')
    .required('Phone number is required'),

  address: Yup.object().shape({
    address_name: Yup.string()
      .required('Address Name is required'),

    country: Yup.string()
      .required('Country is required'),

    state: Yup.string()
      .required('State is required'),

    city: Yup.string()
      .required('City is required'),

    district: Yup.string()
      .required('District is required'),

    pin_code: Yup.string()
      .matches(/^\d{6}$/, 'Pin Code must be 6 digits')
      .required('Pin Code is required'),
  }),
});