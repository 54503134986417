import React, { useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorTemplateOptions } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { InputSwitch } from 'primereact/inputswitch';
import './datagrid.css'
import { Heading } from '../styledcomponents';

interface IDataGridProps {
    deleteByIdList(selectedIds: any): unknown;
    tittle: string;
    data: any[];
    totalRecords: number;
    columns: Array<{
        field: string,
        header: string;
        type?: string;
    }>;
    extraAction: any
    paginationProps(page: string, limit: string): void;
    onCreateOrEdit(id?: string): void;
    deleteByIdList(selectedIds: any): unknown;
    handleStatusUpdate: any | undefined
    SearchBar?: any
}
const DataGrid = (props: IDataGridProps) => {
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(10);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [id, setId] = useState<string | undefined>();
    const [selected, setSelected] = useState([]);
    const dt = useRef<DataTable<any>>(null);
    const [t] = useTranslation()

    const onPageChange = (event: { first: React.SetStateAction<number>; rows: React.SetStateAction<number>; page: React.SetStateAction<number>; }) => {
        setFirst(event.first);
        setLimit(event.rows);
        props.paginationProps(event.page.toString(), event.rows.toString());
    };
    const multipleDelete = () => {
        const selectedIds = (selected as { _id: string }[]).map(item => item._id);
        if (selectedIds.length) {
            props.deleteByIdList({ idList: selectedIds });
        }
        setSelected([]) //hide multiple delete button after delete
    };

    const exportCSV = () => {
        if (dt.current)
            dt.current.exportCSV();
    };

    const handleStatusChange = (e: any, id: string) => {
        const newStatus = e.value ? 'active' : 'terminated';
        props.handleStatusUpdate(e, id, newStatus);
    };

    const statusBodyTemplate = (rawData: any) => {
        return (
            <>
                <div className="p-field-switch statusBodyTemplate">
                    <InputSwitch
                        checked={rawData.status === 'active'}
                        onChange={(e: any) => handleStatusChange(e, rawData._id)}
                    />
                </div>
            </>
        );
    };

    const actionBodyTemplate = (rowData: any) => {
        return (
            <>
                <div className='flex ml-auto'>
                    {<props.extraAction rowData={rowData} />}
                </div>
            </>
        );
    };

    const deleteDialogFooter = (
        <>
            <Button label={t("No").toString()} icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
            <Button label={t("Yes").toString()} icon="pi pi-check" className="p-button-text" onClick={() => { !id && multipleDelete(); setDeleteDialog(false) }} />
        </>
    );

    const paginatorTemp: PaginatorTemplateOptions = {
        layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 120, value: 120 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1 pagination-no" >
                        {t('Items per page') + ': '}
                    </span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                </React.Fragment>
            );
        },
        CurrentPageReport: (options: { first: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; last: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; totalRecords: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; }) => {
            return (
                <span className="mx-1 pagination-no">
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        }
    };

    return (
        <div className="grid">
            <div className="col-12">
                <div><Heading>{t(props.tittle)}</Heading></div>
                <div className='common-header-toolbar'>

                    <div className="flex">
                        {props.SearchBar && <props.SearchBar />}
                        <Button label={t("delete") + ''} icon="pi pi-trash" className="p-button-danger p-button-raised p-button-sm ml-3" onClick={() => { setDeleteDialog(true) }} disabled={!selected || !selected.length} style={{ display: selected && selected.length >= 2 ? 'inline-block' : 'none', backgroundColor: '#11B3CF', border: 'none' }} />
                    </div>
                    <div>
                        <Button label={t("Add New") + ' ' + props.tittle} icon="pi pi-plus" style={{ backgroundColor: '#11B3CF' }} className="p-button-success p-button-raised p-button-sm mr-2" onClick={() => { props.onCreateOrEdit(); }} />
                        <Button icon="pi pi-file-excel" tooltip='Export to Excel' tooltipOptions={{ position: 'left' }} style={{ backgroundColor: '#11B3CF' }} className="p-button-success  p-button-raised p-button-sm" onClick={exportCSV} />
                    </div>

                </div>
                <DataTable
                    ref={dt}
                    value={props.data}
                    sortField="paid_on"
                    sortOrder={-1}
                    selection={selected}
                    onSelectionChange={(e: any) => setSelected(e.value)}
                    dataKey="_id"
                    className="datatable-responsive custom-datatable"
                    emptyMessage={t("no_data")}
                    // scrollDirection="vertical"
                    scrollable
                    scrollHeight="400px"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '4rem' }} frozen alignFrozen='left'></Column>

                    {/* {props.columns.map((col, i) => {
                        return (
                            col.type && col.type === 'date' ?
                                <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={col.header} />
                                :(col.type && col.type === 'capitalize' ?
                                <Column key={col.field} className='capitalize' style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={col.header} />)
                                :(col.type === 'status' ? <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} alignHeader={"center"} body={statusBodyTemplate} header={t(col.header)} />
                                    : <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }}  field={col.field} header={t(col.header)} />)
                        );
                    })
                    } */}
                    {props.columns.map((col, i) => {
                        return (
                            col.type && col.type === 'date' ?
                                <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} header={col.header} />
                                : (col.type && col.type === 'capitalize' ?
                                    <Column key={col.field} className='capitalize' style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field}  header={col.header} />
                                    : (col.type === 'status' ? <Column className='capitalize' key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} alignHeader={"center"} body={statusBodyTemplate} header={t(col.header)} />
                                        : <Column key={col.field} style={{ flexGrow: 1, flexBasis: 150, overflow: 'hidden', textOverflow: 'ellipsis' }} field={col.field} header={t(col.header)} />)
                                )
                        );
                    })}
                    <Column header={t("action")} className="action" body={actionBodyTemplate} alignHeader={'left'} style={{ minWidth: '150px' }} alignFrozen="right" frozen ></Column>

                </DataTable>
                <Paginator template={paginatorTemp} first={first} rows={limit} totalRecords={props.totalRecords} onPageChange={onPageChange} className="justify-content-end my-3"></Paginator>

                <Dialog visible={deleteDialog} style={{ width: '450px' }} header={t("Confirm")} modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
                    <div className="flex align-items-center justify-content-center">
                        <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                        {!id ? <span>{t('Are you sure you want to delete the selected items')}?</span> : <span>{t('Are you sure you want to delete')} ?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
};

export default DataGrid;
