
export const model = [
 
    {
       
        to: '/app',
        items: [
                    {
                        label: 'customers',
                        icon: 'pi pi-fw pi-users',
                        to: '/app/customer'
                    },
                    {
                        label: 'offers',
                        icon: 'pi pi-fw pi-box',
                        to: '/app/offers'
                    }           
        ]
    },
];
