import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../../components/common/ErrorMessage';
import { loginValidation } from '../../../validation';
import { Formik, Form, FormikHelpers } from 'formik';
import { login } from '../../../services';
import { useNavigate, Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../../context/authcontext';
import { useDispatch } from 'react-redux';
import { fetchUserPermissionsRequest } from '../../../store/actions/actions';
import './login.css'
import { setCookie } from '../../../utils/cookieUtils';


function Login() {
  const dispatch = useDispatch();
  const { setIsAuthenticated,setUserId  } = useContext(AuthContext)
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = { user_name: '', password: '' };
  const onSubmit = async (e: any, actions: FormikHelpers<{
    user_name: string;
    password: string;
  }>) => {
    const res = await login(e);
    if (res) {
      setCookie('user', JSON.stringify(res.data));
      dispatch(fetchUserPermissionsRequest(res.data.user.id));
      setUserId(res.data.user.id);
      setIsAuthenticated(true);
      setCookie('isAuthenticated', 'true');
      navigate('/app');
  }
  };


  return (
    <Formik initialValues={initialValues} validationSchema={loginValidation} onSubmit={onSubmit}>
      {
        ({ isSubmitting, errors, getFieldProps, touched, isValid }) => (<Form>

          <div className="fmt-login-layout">
            <div className="fmt-login-layout-blob">

            
              
              <div className='login-component' >
                <div className="flex flex-column align-items-center justify-content-center">
                  <div style={{ borderRadius: '56px', padding: '0.3rem', }}>
                    <div className="w-full surface-card py-8 px-5 sm:px-8" style={{ borderRadius: '53px' }}>
                    <div className="card">
                        <div className="card-body">
                          <div className="text-center mb-">
                            <div className="text-900 text-3xl font-medium ">LMS</div>
                            <span className="text-600 font-medium">Sign in to continue</span>
                          </div>
                        </div>
                      </div>
                      
                      
                      <div className='auth-form'>
                        <div className="flex flex-column gap-2 mb-3">
                          <label htmlFor="email1" className="auth-label ">
                          <i className="pi pi-user mr-2"></i>
                            Username
                          </label>
                          <InputText id="email1" aria-describedby='username-help' type="text" className={`w-full md:w-30rem ${touched.user_name && errors.user_name ? 'p-invalid' : ''}`}  {...getFieldProps(('user_name'))} name='user_name' placeholder='Username'/>
                          {touched.user_name && errors.user_name && <ErrorMessage message={errors.user_name} id="username-help" />}
                        </div>
                        <div className="flex flex-column gap-2 ">
                          <label htmlFor="password1" className="auth-label">
                          <i className="pi pi-key mr-2"></i>
                            Password
                          </label>
                          <Password id="password1" aria-describedby='password-help' className={`w-full ${touched.password && errors.password ? 'p-invalid' : ''}`} inputClassName='w-full md:w-30rem' feedback={false}  {...getFieldProps(('password'))} name='password' toggleMask placeholder='Password' />
                          {touched.password && errors.password && <ErrorMessage message={errors.password} id="password-help" />}
                        </div>
                        <div className="flex align-items-center justify-content-between mb-5 gap-5">
                          <Link to="/forgotpassword" className="font-medium no-underline ml-auto text-right cursor-pointer mt-2" style={{ color: '#11B3CF' }}>Forgot Password?</Link>
                        </div>
                        <Button label={isSubmitting ? '' : 'Login'} className="w-full p-2 text-md auth-login-button" type='submit' loading={isSubmitting} />
                      </div>
                     
                    </div>
                    
                  </div>
                  <div >Comptivia - LMS</div>
                  <div >© 2023 Comptivia Technologies India Pvt Ltd . All rights reserved.</div>
                </div>
               
              </div>
              
            </div>
          </div>
        </Form>)
        
      }
    </Formik>
    

  );
}

export default Login;
