
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import React, { useEffect, useContext, } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MenuContext } from '../../../context/menucontext';
import { useTranslation } from 'react-i18next';
const AppMenuitem = (props) => {
    const { activeMenu, setActiveMenu } = useContext(MenuContext);
    const router = useLocation();
    const item = props.item;
    const key = props.parentKey ? props.parentKey + '-' + props.index : String(props.index);
    const isActiveRoute = item.to && router.pathname === item.to;
    const active = activeMenu === key || activeMenu.startsWith(key + '-');
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (item.to && router.pathname === item.to) {
            setActiveMenu(key);
        }

        const onRouteChange = (url) => {
            if (item.to && item.to === url) {
                setActiveMenu(key);
            }
        };
    }, []);

    const itemClick = (event) => {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return;
        }

        //execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // toggle active state
        if (item.items)
            setActiveMenu(active ? props.parentKey : key);
        else
            setActiveMenu(key);
    };

    const subMenu = item.items && item.visible !== false && (
        <CSSTransition timeout={{ enter: 1000, exit: 450 }} classNames="layout-submenu" in={props.root ? true : active} key={item.label}>
            <ul>
                {item.items.map((child, i) => {
                    return <AppMenuitem item={child} index={i} className={child.badgeClass} parentKey={key} key={child.label} />;
                })}
            </ul>
        </CSSTransition>
    );
    const iconPath = '../../../assets/icons/' + item.icon;
    return (
        <li className={classNames({ 'layout-root-menuitem': props.root, 'active-menuitem': active })}>
            {props.root && item.visible !== false && <div className="layout-menuitem-root-text">{t(item.label)}</div>}
            {(!item.to || item.items) && item.visible !== false ? (
                <Link to={item.url} onClick={(e) => itemClick(e)} className={classNames(item.class, 'p-ripple')} target={item.target} tabIndex="0">
                    <i className={classNames('layout-menuitem-icon', item.icon)}></i>
                    {/* <img src={item.icon} alt={item.label} className="layout-menuitem-icon"style={{ width: '20px', height: '15px' }} /> */}
                    <span className="layout-menuitem-text">{t(item.label)}</span>
                    {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                    <Ripple />
                </Link>
            ) : null}

            {item.to && !item.items && item.visible !== false ? (
                <Link to={item.to} replace={item.replaceUrl} onClick={(e) => itemClick(e)} className={classNames(item.class, 'p-ripple', { 'active-route': isActiveRoute })} target={item.target} tabIndex="0">
                    <i className={classNames('layout-menuitem-icon', item.icon)}></i>
                    {/* <img src={item.icon} alt={item.label} className="layout-menuitem-icon" style={{ width: '20px', height: '15px' }} /> */}
                    <span className="layout-menuitem-text">{t(item.label)}</span>
                    {item.items && <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>}
                    <Ripple />
                </Link>
            ) : null}

            {subMenu}
        </li>
    );
};

export default AppMenuitem;
