import React from "react";
import { InputGroup } from "react-bootstrap";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

const SearchBar = ({ searchQuery, handleInputChange, handleSearch }: any) => {
  const [t] = useTranslation()

  return (
    <div className="search-bar">
      <InputGroup>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            autoFocus
            key={searchQuery}
            value={searchQuery}
            onChange={handleInputChange}
            placeholder={t("Search...") as string}
            style={{ width: "300px",borderRadius:'0px',height:'2.5rem' }}
          />
        </span>
        <Button
          label={t("Search") as string}
          onClick={handleSearch}
          style={{ backgroundColor: "#11B3CF",height:'2.5rem' }}
          className="p-button-success"
        />
      </InputGroup>

    </div>
  );
};

export default SearchBar;
