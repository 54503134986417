import React, { useState, useEffect } from "react";
import DataGrid from "../../../components/datagrid/DataGrid";
import { customerSvc, offersSvc } from '../../../services';
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import ActionButton from "../../../components/common/buttons/actionButton";
import { useTranslation } from "react-i18next";
import SearchBar from "../../../components/common/searchBar/SearchBar";
import OfferModal from "./offerModal";


const OfferCustomerList: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalRecords, settotalRecords] = useState(0);
  const [page, setPage] = useState('1');
  const [limit, setLimit] = useState('10');
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [visible, setVisible] = useState(false);
  const [id, setId] = useState<string|null>('');
  const [searchQuery, setSearchQuery] = useState(''); //For search bar
  const [t] = useTranslation()

  const columns = [
    { field: 'customer_id.sl_no', header: t('Sl No') },
    { field: 'customer_id.first_name', header: t('Customer Name') },
    { field: 'borrow_Type', header: t('Borrow Type'),type:'capitalize'},
    { field: 'disburse_Type', header: t('Disburse Type'),type:'capitalize'},
    { field: 'rate', header: t('Rate') },
    { field: 'phone', header: t('phone') },
  ];

  const offerData = () => {
    offersSvc.getAll(Number(limit), Number(page)).then(res => {
      setTableData(res.data.user);
      settotalRecords(res.data.recordcount);
    });
  }
  useEffect(() => {
    offerData()
  }, [limit, page]);
  const getPaginationProps = (p: string, l: string) => {
    setPage((Number(p) + 1).toString());
    setLimit(l);
  };



  const onCreateOrUpdate = (i: string) => {
    setId(i);
    setVisible(true);
  };
  const deleteRecord = (i: string) => {
    offersSvc.Delete(i).then(res => {
      if (res) {
        toast.success('Customer offer deleted successfully');
        offerData();
      } else {
        toast.error('Failed to delete customer offer');
      }
    });
  }

  const deleteByIdList = (i: any) => {
    customerSvc.multipleDelete(i).then(res => {
      if (res) {
        toast.success('Customers offer deleted successfully');
        offerData();
      } else {
        toast.error('Failed to delete customers offer');
      }
    });
  }




  const permissionList = useSelector((state: any) => state.permissions.permissions);


  const deleteDialogFooter = (
    <>
      <Button label={t("No").toString()} icon="pi pi-times" className="p-button-text" onClick={() => setDeleteDialog(false)} />
      <Button label={t("Yes").toString()} icon="pi pi-check" className="p-button-text" onClick={() => { deleteRecord(id ?? " "); setDeleteDialog(false) }} />
    </>
  );

  // Implement Search bar 
  const handleSearch = () => {
    offersSvc.serchCustomer(searchQuery).then(res => {
      if (res) {
        setTableData(res.data.user);
      }
    });

  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (value === '') {
      offerData();
    }
  };
  const OfferSearch = () => {

    return (
      <React.Fragment>
        <SearchBar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          handleSearch={handleSearch}
        />
      </React.Fragment>
    );
  };


  const extraAction = ({ rowData }: any) => {
    const hasUpdatePermission = permissionList && permissionList.includes('pages.admin.interestRate.update');
    const hasDeletePermission = permissionList && permissionList.includes('pages.admin.interestRate.delete');
    return <>
      {hasUpdatePermission && (<ActionButton label={t("Edit") + ''} icon="pi pi-pencil" className="p-button-secondary" onClick={() => { onCreateOrUpdate(rowData._id); }} />)}
      {hasDeletePermission && (<ActionButton label={t("Delete") + ''} icon="pi pi-trash" className="p-button-secondary" onClick={() => { setDeleteDialog(true); setId(rowData._id) }} />)}
    </>;
  };
  return (
    <>
      <DataGrid tittle={t("Offer Rate")} data={tableData} columns={columns} totalRecords={totalRecords} paginationProps={getPaginationProps} onCreateOrEdit={onCreateOrUpdate}
        deleteByIdList={deleteByIdList} extraAction={extraAction} handleStatusUpdate={undefined} SearchBar={OfferSearch} />

      <Dialog visible={deleteDialog} style={{ width: '450px' }} header={t("Confirm")} modal onHide={() => setDeleteDialog(false)} footer={deleteDialogFooter}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {!id ? <span>{t('Are you sure you want to delete the selected items')}?</span> : <span>{t('Are you sure you want to delete')} ?</span>}
        </div>
      </Dialog>
      {visible && <OfferModal visible={visible} setVisible={setVisible} updateTableData={offerData} viewOnly={false} id={id} />}


    </>
  );
};

export default OfferCustomerList;