import React from 'react';
import { Toast } from 'primereact/toast';

const ToastMessage = ({ showToast, severity, summary, detail, life }:any) => {
  return (
    <Toast ref={(el) => showToast.current = el} position="top-right" baseZIndex={1000}  />
  );
};

export default ToastMessage;
