import { Link, Navigate, useNavigate } from 'react-router-dom';
import { classNames } from 'primereact/utils';
import './header.css'
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LayoutContext } from '../../../context/layoutcontext';
import Language from '../language/language'
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { getCookie, removeCookie } from '../../../utils/cookieUtils';
import { profileSvc } from '../../../services';
import logo from '../../../assets/comptivia-newlogo.png'
import SiblingAppsSideBar from './siblingAppsSideBar';
import { InputSwitch } from 'primereact/inputswitch';
import { useTranslation } from 'react-i18next';

const Header = forwardRef((props, ref) => {
    const { layoutConfig, layoutState, onMenuToggle, showProfileSidebar } = useContext(LayoutContext);
    const menubuttonRef = useRef(null);
    const topbarmenuRef = useRef(null);
    const topbarmenubuttonRef = useRef(null);
    const menu = useRef<Menu>(null);
    const [t] = useTranslation();
    const navigate = useNavigate()
    const [checked, setChecked] = useState(false);
    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
        topbarmenu: topbarmenuRef.current,
        topbarmenubutton: topbarmenubuttonRef.current
    }));
    const handleLogout = () => {
        removeCookie('user');
        removeCookie('isAuthenticated');
        window.location.href = '/login';
    }

    // const { userId } = useContext(AuthContext);
    const [visibleRight, setVisibleRight] = useState<boolean>(false);
    const [userName, setuserName] = useState('')
    const profileDetails = () => {
        const user = getCookie("user")
        const data = user
        const userId = data.user.id;
        if (userId) {

            profileSvc.get(userId)
                .then(res => {
                    setuserName(res.data.display_name)
                })
        }
    }

    useEffect(() => {
        profileDetails()
    }, []);

    useEffect(() => {
        // Redirect to the old URL when the switch is turned on
        if (checked) {
            window.location.href = 'https://fmt.comptivia.com';
        }
    }, [checked]);


    const items = [
        {
            label: userName,

            items: [
                {
                    label: t('My Profile'),
                    icon: 'pi pi-user',
                    command: () => {
                        navigate('/app/profile');
                    }

                },
                {
                    label: t('Log Out'),
                    icon: 'pi pi-sign-out',
                    command: () => {
                        handleLogout();
                    }
                },

            ]
        }
    ];

    return (
        <div className="layout-topbar">
            <Link to={"/app"} className="layout-topbar-logo">
                <img src={layoutConfig.colorScheme === 'light' ? logo : logo} width={150} height={150} alt="logo" />

                {/* <img src={layoutConfig.colorScheme === 'light' ? logo : ""} width={120} height={120} alt="logo" /> */}

            </Link>

            <button ref={menubuttonRef} type="button" className="p-link layout-menu-button layout-topbar-button" onClick={onMenuToggle}>
                <i className="pi pi-bars" />
            </button>

            <button ref={topbarmenubuttonRef} type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={showProfileSidebar}>
                <i className="pi pi-ellipsis-v" />
            </button>


            <div ref={topbarmenuRef} className={classNames('layout-topbar-menu', { 'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible })}>
            <div className="flex justify-content-center align-items-center">
                    <span className='app-switch-label'>
                        {t('switch to old application')}
                    </span>
                     <InputSwitch checked={checked} onChange={(e) => {
                        if(e.value==null)
                        setChecked(false)
                         else
                          setChecked(e.value)
                    }} />
             </div>
                <div className='mt-1 mr-3'><Language /></div>
                <Menu model={items} popup ref={menu} />

                <Avatar label={userName
                    .split(' ')
                    .map(name => name[0])
                    .join('')
                    .toUpperCase()} className="p-overlay-badge mt-2 mr-3" onClick={(e) => menu.current?.toggle(e)} style={{ backgroundColor: '#11B3CF', color: '#ffffff', marginLeft: '5px', fontFamily: 'Raleway' }} shape="circle" >

                </Avatar>
                {/* <button icon="pi pi-th-large" onClick={() => setVisibleRight(true)} /> */}
                <button className="p-link layout-topbar-button " onClick={() => setVisibleRight(true)} >

                    <i className="pi pi-th-large"></i>

                </button>

                {visibleRight && <SiblingAppsSideBar visible={visibleRight} setVisible={setVisibleRight} />}




            </div>
        </div>
    );
}
)
export default Header;

