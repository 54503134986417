import { Form, Formik, FormikHelpers } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AutoComplete } from "primereact/autocomplete";
import ErrorMessage from "../../../components/common/ErrorMessage";
import { IOfferRate } from "../../../interfaces";
import { offersSvc, customerSvc } from '../../../services';
import { OfferRateSave } from "../../../validation";
import useDebounce from "../../../helpers/debounce";
import { t } from "i18next";
import { CancelButton } from "../../../components/styledcomponents";
import { ProgressSpinner } from 'primereact/progressspinner';
import './offer.css'
const OfferModal = ({ setVisible, visible = false, viewOnly = true, id, updateTableData }: IInterestRateModal) => {
    const initial: IOfferRate = {
        customer_id: "",
        borrow_Type: "",
        disburse_Type: "",
        emi_Interest: "",
        rate: "",
    }
    const borrow_type = [{ label: 'Weekly', value: 'weekly' }, { label: 'Monthly', value: 'monthly' }];
    const disburse_type = [{ label: 'Full', value: 'full' }, { label: 'Reduced', value: 'reduced' }];
    const [initialValues, setInitialValues] = useState<IOfferRate>(initial)
    const [itemId, setItemId] = useState<string | null>(id)
    const [customerSelected, setCustomerSelected] = useState(null);
    const [customer, setCustomer] = useState([]);
    const [customerSearch, setcustomerSearch] = useState<string>('');
    const debouncedValue = useDebounce<string>(customerSearch, 500);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      if (debouncedValue && debouncedValue.length > 0)
        customerSvc.getAutoSuggest(customerSearch).then(res => {
          setCustomer(res.data);
        });
    }, [debouncedValue]);
    
    useEffect(() => {
        if (id&&visible) {
            setLoading(true)
            offersSvc.get(id).then(res => {
                customerSvc.getAutoSuggestById(res.data.customer_id).then(res => {
                    setCustomerSelected(res.data)
                })
                setInitialValues({ ...res.data });
                setLoading(false)

            });
        }
        

    }, [id,visible]);



    const onSubmit = async (values: IOfferRate, formikHelpers: FormikHelpers<IOfferRate>) => {

        const res = id ? await offersSvc.update(itemId, values) : await offersSvc.save(values);
        if(res){
        toast.success("Success");
        formikHelpers.resetForm()
        updateTableData()
        setVisible(false)
        }
    };
    const onHide = () => {
        setInitialValues(initial)
        setItemId(null)
        setVisible(false)
    }
    const DialogFooter = (
        <div className="flex flex-wrap justify-content-end gap-3">
            <CancelButton label={t("Cancel") + ''} onClose={onHide} />
            <Button label={id ? 'Update' : 'Save'} icon="pi pi-check" className="p-button-success p-button-raised"  style={{ background: '#11B3CF' }}  type='submit' />
        </div>);

    return (
        <Dialog header={t("Interest Rate")} visible={visible} maximizable style={{ width: '50vw' }} onHide={onHide}>
            {
           loading && 
           <Dialog visible={loading} style={{ width: '20vw' }} onHide={() => {}} closable={false}>
                <div className="loading-card-dialog-content">
                <p>Loading</p>
                <ProgressSpinner />
                <p>Please wait a moment...</p>
                </div>
            </Dialog>
            }
            <Formik enableReinitialize initialValues={initialValues} validationSchema={OfferRateSave} onSubmit={onSubmit}>
                {
                    ({ values, errors, getFieldProps, touched, setFieldValue }) => (
                        <Form>
                            <div className="grid p-fluid mt-3">

                                <div className="field col-12 md:col-6">
                                    <label htmlFor="customer_id">{t("customer")}</label>
                                    <AutoComplete field="name" value={customerSelected} suggestions={customer} completeMethod={(e) => { setcustomerSearch(e.query); }} onChange={(e) => { setFieldValue("customer_id", e.value.value); setCustomerSelected(e.value); }} />
                                    {touched.customer_id && errors.customer_id && <ErrorMessage message={errors.customer_id} id="customer_id" />}
                                </div>



                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="borrow_Type">{t("Borrow Type")}</label>
                                    <Dropdown {...getFieldProps(('borrow_Type'))} name='borrow_Type' options={borrow_type}
                                        id="borrow_Type" className="w-full " disabled={!!id}  />
                                    {/* </span> */}
                                    {touched.borrow_Type && errors.borrow_Type && <ErrorMessage message={errors.borrow_Type} id="borrow_Type" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="disburse_Type">{t("Disburse Type")}</label>
                                    <Dropdown {...getFieldProps(('disburse_Type'))} name='disburse_Type' options={disburse_type}
                                        id="disburse_Type" className="w-full" disabled={!!id} />
                                    {/* </span> */}
                                    {touched.disburse_Type && errors.disburse_Type && <ErrorMessage message={errors.disburse_Type} id="disburse_Type" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label "> */}
                                    <label htmlFor="emi_Interest ">{t("Emi Interest (%)")}</label>
                                    <InputNumber id="emi_Interest " minFractionDigits={2} maxFractionDigits={2} onChange={(e) => setFieldValue('emi_Interest', e.value)} value={values.emi_Interest} name='emi_Interest' />
                                    {/* </span> */}
                                    {touched.emi_Interest && errors.emi_Interest && <ErrorMessage message={errors.emi_Interest} id="emi_Interest" />}
                                </div>

                                <div className="field col-12 md:col-6">
                                    {/* <span className="p-float-label"> */}
                                    <label htmlFor="rate">{t("Rate (%)")}</label>
                                    <InputNumber id="rate" onChange={(e) => setFieldValue('rate', e.value)} value={values.rate} name='rate' minFractionDigits={2} maxFractionDigits={2} />
                                    {/* </span> */}
                                    {touched.rate && errors.rate && <ErrorMessage message={errors.rate} id="rate" />}
                                </div>


                            </div>
                            {DialogFooter}
                        </Form>)
                }
            </Formik>

        </Dialog>
    );
};

export default OfferModal;



interface IInterestRateModal {
    visible: boolean;
    setVisible(val: boolean): void;
    viewOnly: boolean,
    id: string | null;
    updateTableData:any
}


